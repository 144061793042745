/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import homeSchema from "../schemas/homeSchema"
import categorySchema from "../schemas/categorySchema"

function SEO({
  description,
  lang,
  meta,
  title,
  type,
  url,
  article,
  category,
  isCategory,
  datePublished,
  dateModified,
  image,
  canonical,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const metaType = type || "website"
  const metaUrl = url || "https://iqaraislam.com/"
  const metaCategory = category
  const metaDate = datePublished
  const metaUpdateDate = dateModified
  const metaImage = image

  const articleSchema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://iqaraislam.com/#organization",
        name: "Iqara Islam",
        url: "https://iqaraislam.com/",
        sameAs: [
          "https://www.facebook.com/IqaraIslam",
          "https://www.instagram.com/iqaraislam.port/",
          "https://www.linkedin.com/company/iqara-islam",
          "https://www.youtube.com/channel/UCSBp8QB_C7Pocz1nroLUrrA",
        ],
        logo: {
          "@type": "ImageObject",
          "@id": "https://iqaraislam.com/#logo",
          inLanguage: "pt-BR",
          url:
            "https://images.iqaraislam.com/wp-content/uploads/2019/09/Logo-Iqara-Islam-Retina-1.png",
          width: 570,
          height: 180,
          caption: "Iqara Islam",
        },
        image: {
          "@id": "https://iqaraislam.com/#logo",
        },
      },
      {
        "@type": "WebSite",
        "@id": "https://iqaraislam.com/#website",
        url: "https://iqaraislam.com/",
        name: "Iqara Islam",
        inLanguage: "pt-BR",
        description: "Sua Fonte de Islam Sunita",
        publisher: {
          "@id": "https://iqaraislam.com/#organization",
        },
        potentialAction: {
          "@type": "SearchAction",
          target: "https://iqaraislam.com/search?s={search_term_string}",
          "query-input": "required name=search_term_string",
        },
      },
      {
        "@type": "ImageObject",
        "@id": `${url}/#primaryimage`,
        inLanguage: "pt-BR",
        url: image,
        width: 1200,
        height: 628,
        caption: title,
      },
      {
        "@type": "WebPage",
        "@id": `${url}/#webpage`,
        url: url,
        name: title,
        isPartOf: {
          "@id": "https://iqaraislam.com/#website",
        },
        inLanguage: "pt-BR",
        primaryImageOfPage: {
          "@id": `${url}/#primaryimage`,
        },
        datePublished: metaDate,
        dateModified: metaUpdateDate,
        description: metaDescription,
      },
      {
        "@type": "Article",
        "@id": `${url}/#article`,
        isPartOf: {
          "@id": `${url}/#webpage`,
        },
        author: {
          "@id":
            "https://iqaraislam.com/#/schema/person/13bd408ff759a4d0c4cad95ac0b4ed06",
        },
        headline: title,
        datePublished: metaDate,
        dateModified: metaUpdateDate,
        commentCount: 0,
        mainEntityOfPage: {
          "@id": `${url}/#webpage`,
        },
        publisher: {
          "@id": "https://iqaraislam.com/#organization",
        },
        image: {
          "@id": `${url}/#primaryimage`,
        },
        articleSection: metaCategory,
        inLanguage: "pt-BR",
      },
      {
        "@type": ["Person"],
        "@id":
          "https://iqaraislam.com/#/schema/person/13bd408ff759a4d0c4cad95ac0b4ed06",
        name: "A Redação",
        description:
          "A Equipe de Redação do Iqara Islam é multidisciplinar e composta por especialistas na Religião Islâmica, profissionais da área de Marketing, Ilustração/Design, História, Administração, Tradutores Especializados (Árabe e Inglês).",
        sameAs: [],
      },
    ],
  }

  if (article) {
    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitle}
        link={[
          { rel: "canonical", href: canonical },
          {
            rel: "stylesheet",
            href:
              "https://fonts.googleapis.com/css?family=Merriweather:300,400,700|Rubik:400,500,700&display=swap",
            type: "text/css",
          },
        ]}
        meta={[
          {
            name: `viewport`,
            content: `width=device-width, initial-scale=1, minimum-scale=1, shrink-to-fit=no`,
          },
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:locale`,
            content: "pt_BR",
          },
          {
            property: `og:url`,
            content: metaUrl,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:site_name`,
            content: "Iqara Islam",
          },
          {
            property: `article:publisher`,
            content: "https://www.facebook.com/IqaraIslam",
          },
          {
            property: `article:section`,
            content: metaCategory,
          },
          {
            property: `article:published_time`,
            content: metaDate,
          },
          {
            property: `article:modified_time`,
            content: metaUpdateDate,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:updated_time`,
            content: metaUpdateDate,
          },
          {
            property: `og:image`,
            content: metaImage,
          },
          {
            property: `og:image:secure_url`,
            content: metaImage,
          },
          {
            property: `og:image:width`,
            content: 1200,
          },
          {
            property: `og:image:height`,
            content: 800,
          },
          {
            property: `og:type`,
            content: metaType,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
      >
        <script type="application/ld+json">
          {JSON.stringify(articleSchema)}
        </script>
      </Helmet>
    )
  } else if (isCategory) {
    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        link={[
          { rel: "canonical", href: "https://iqaraislam.com" },
          {
            rel: "stylesheet",
            href:
              "https://fonts.googleapis.com/css?family=Merriweather:300,400,700|Rubik:400,500,700&display=swap",
            type: "text/css",
          },
        ]}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:locale`,
            content: "pt_BR",
          },
          {
            property: `og:url`,
            content: metaUrl,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:image`,
            content:
              "https://images.iqaraislam.com/wp-content/uploads/2019/09/Um-retrato-da-vida-dos-Companheiros-do-Profeta-Muhammad.jpg",
          },
          {
            property: `og:site_name`,
            content: "Iqara Islam",
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: metaType,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
      >
        <script type="application/ld+json">
          {JSON.stringify(categorySchema)}
        </script>
      </Helmet>
    )
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      link={[
        { rel: "canonical", href: "https://iqaraislam.com" },
        {
          rel: "stylesheet",
          href:
            "https://fonts.googleapis.com/css?family=Merriweather:300,400,700|Rubik:400,500,700&display=swap",
          type: "text/css",
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:locale`,
          content: "pt_BR",
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content:
            "https://images.iqaraislam.com/wp-content/uploads/2019/09/Um-retrato-da-vida-dos-Companheiros-do-Profeta-Muhammad.jpg",
        },
        {
          property: `og:site_name`,
          content: "Iqara Islam",
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: metaType,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(homeSchema)}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `pt-BR`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
