import React, { useRef } from "react"

import useOnClickOutside from "use-onclickoutside"
import { navigate } from "gatsby"

const Search = ({ isOpen, setOpen }) => {
  const queryInput = useRef(null)

  const handleFormState = () => {
    if (setOpen) setOpen(false)
  }

  useOnClickOutside(queryInput, handleFormState)

  const handleSubmit = e => {
    e.preventDefault()
    navigate(
      `/search?s=${queryInput.current.value}`,
      { state: { query: queryInput.current.value } },
      true
    )
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={`menu-search-form ${isOpen ? `menu-search-form-open` : null}`}
    >
      <input
        type="text"
        ref={queryInput}
        id="search-text-input"
        name="query"
        placeholder="Pesquisar no Iqara Islam"
        autoFocus
        required
      />
      <input type="submit" value="Buscar" />
    </form>
  )
}

export default Search
