import React from "react"

const ICNFacebook = ({ width, style }) => {
  return (
    <svg
      data-name="Layer 21"
      height={width}
      id="Layer_21"
      viewBox="0 0 24 24"
      width={width}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title />
      <path d="M16.75,9H13.5V7a1,1,0,0,1,1-1h2V3H14a4,4,0,0,0-4,4V9H8v3h2v9h3.5V12H16Z" />
    </svg>
  )
}

export default ICNFacebook
