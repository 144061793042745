import React from "react"

const ICNYouTube = ({ width, style, fill }) => {
  return (
    <svg
      height={width}
      version="1.1"
      viewBox="0 0 60 60"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <title />
      <desc />
      <defs />
      <g
        fill="none"
        fillRule="evenodd"
        id="black"
        stroke="none"
        strokeWidth="1"
      >
        <g id="slice" transform="translate(-500.000000, -100.000000)" />
        <g fill={fill} id="youtube" transform="translate(10.000000, 16.000000)">
          <path
            d="M38.7915918,6.76160896 C38.7915918,6.76160896 38.4166292,4.11897479 37.2666018,2.95488634 C35.8089347,1.42776592 34.1740125,1.42052232 33.4245134,1.33104261 C28.0582872,0.942871094 20.0085219,0.942871094 20.0085219,0.942871094 L19.9914781,0.942871094 C19.9914781,0.942871094 11.9421389,0.942871094 6.57548658,1.33104261 C5.82598746,1.42052232 4.19191748,1.42776592 2.73339817,2.95488634 C1.58337082,4.11897479 1.2092604,6.76160896 1.2092604,6.76160896 C1.2092604,6.76160896 0.825775918,9.86485061 0.825775918,12.9680923 L0.825775918,15.8778873 C0.825775918,18.9807028 1.2092604,22.0839445 1.2092604,22.0839445 C1.2092604,22.0839445 1.58337082,24.7265786 2.73339817,25.8906671 C4.19191748,27.4182136 6.10806161,27.3692128 6.96152762,27.5294241 C10.0298296,27.823855 20,27.9150391 20,27.9150391 C20,27.9150391 28.0582872,27.9026823 33.4245134,27.5149369 C34.1740125,27.4254572 35.8089347,27.4182136 37.2666018,25.8906671 C38.4166292,24.7265786 38.7915918,22.0839445 38.7915918,22.0839445 C38.7915918,22.0839445 39.1742241,18.9807028 39.1742241,15.8778873 L39.1742241,12.9680923 C39.1742241,9.86485061 38.7915918,6.76160896 38.7915918,6.76160896 Z M16.0411619,19.4029619 L16.0394575,8.6283262 L26.4012082,14.0341791 L16.0411619,19.4029619 Z"
            id="Fill-195"
          />
        </g>
      </g>
    </svg>
  )
}

export default ICNYouTube
