import React from "react"

const ICNVimeo = ({ width, style }) => {
  return (
    <svg
      enableBackground="new 0 0 56.693 56.693"
      height={width}
      id="Layer_1"
      version="1.1"
      viewBox="0 0 56.693 56.693"
      width={width}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.602,22.974l2.01,2.636c0,0,4.146-3.267,5.528-1.634c1.382,1.634,6.656,21.357,8.417,24.997  c1.537,3.192,5.777,7.413,10.426,4.397c4.646-3.014,20.098-16.205,22.863-31.781c2.764-15.574-18.594-12.313-20.854,1.256  c5.652-3.395,8.671,1.38,5.778,6.784c-2.889,5.399-5.527,8.921-6.908,8.921c-1.379,0-2.441-3.612-4.021-9.928  c-1.635-6.53-1.624-18.291-8.416-16.958C12.019,12.921,3.602,22.974,3.602,22.974z" />
    </svg>
  )
}

export default ICNVimeo
