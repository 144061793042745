import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import PropTypes, { func } from "prop-types"

import StickMenu from "./stickMenu"
import logo from "../images/iqara-logo-white.png"
import burgerMenu from "../images/svg/icon-menu.svg"
import Search from "./search"

import ICNCart from "../icons/iconCart"
import ICNSearch from "../icons/iconSearch"
import ICNFacebook from "../icons/iconFacebook"
import ICNInstagram from "../icons/iconInstagram"
import ICNVimeo from "../icons/iconVimeo"
import ICNYouTube from "../icons/iconYoutube"
import ICNSpotify from "../icons/iconSpotify"

const Header = ({ siteTitle, open, setOpen, stories }) => {
  const regularStories = stories.filter(s => !s.node.storyCollection)
  const collectionStories = stories.filter(s => s.node.storyCollection)

  const [openSearch, setOpenSearch] = useState(false)

  const [showStoryCollections, setShowStoryCollections] = useState(false)

  const handleOpenSearch = () => {
    setOpenSearch(!openSearch)
  }

  return (
    <>
      <header>
        <div className="menu-wrapper">
          <StickMenu open={open} setOpen={setOpen}></StickMenu>
          <div className="menu-top">
            <div className="menu-left-section">
              <div className="menu-burger" onClick={() => setOpen(!open)}>
                <img
                  src={burgerMenu}
                  alt="Iqara Islam"
                  width="25"
                  height="25"
                />
                <span>MENU</span>
              </div>
            </div>

            <div className="menu-mid-section">
              <Link to="/">
                <img
                  src={logo}
                  alt="Iqara Islam"
                  style={{ marginBottom: "-4px" }}
                />
              </Link>
            </div>

            <div className="menu-right-section" id="menu-right-section">
              {openSearch && (
                <Search isOpen={openSearch} setOpen={setOpenSearch} />
              )}

              <a href="https://nizam.app/pt" target="_blank">
                <div
                  height="26"
                  style={{
                    paddingRight: 5,
                  }}
                  className="nizam-menu-logo"
                ></div>
              </a>

              {/* <a
              href="https://caravansaray.com.br"
              target="_blank"
              className="cart-menu-icon"
            >
              <ICNCart
                width="29"
                style={{
                  marginBottom: "-1px",
                  paddingRight: 15,
                  fill: "#fff",
                }}
              />
            </a> */}

              <a
                href="https://www.facebook.com/IqaraIslam/"
                target="_blank"
                className="social-icons"
                rel="noreferrer"
              >
                <ICNFacebook width="30" style={{ marginBottom: "-2px" }} />
              </a>
              <a
                href="https://www.instagram.com/iqaraislam.port/"
                target="_blank"
                className="social-icons"
                rel="noreferrer"
              >
                <ICNInstagram
                  width="29"
                  style={{ marginBottom: "-1px", marginLeft: "-4px" }}
                />
              </a>
              <a
                href="https://vimeo.com/iqaraislam"
                target="_blank"
                className="social-icons"
                rel="noreferrer"
              >
                <ICNVimeo width="30" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCSBp8QB_C7Pocz1nroLUrrA"
                target="_blank"
                className="social-icons"
                rel="noreferrer"
                style={{ marginRight: "-5px" }}
              >
                <ICNYouTube
                  width="50"
                  fill="#fff"
                  style={{ marginBottom: "-12px", marginLeft: "-5px" }}
                />
              </a>
              <a
                href="https://open.spotify.com/show/51Ko9GfLJgeJTxzlIo8XoP"
                target="_blank"
                className="social-icons"
                rel="noreferrer"
              >
                <ICNSpotify
                  width="27"
                  style={{ marginBottom: "-1px", marginLeft: "4px" }}
                />
              </a>

              <div
                className={`search-holder ${
                  openSearch ? `search-holder-open` : null
                }`}
                onClick={handleOpenSearch}
              >
                <ICNSearch
                  width="24"
                  style={{ marginBottom: "-1px", marginLeft: "4px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* <div className="main main-stories" style={{ maxWidth: "1100px" }}>
        <div className="stories-carousel">
          <div className="stories">
            {regularStories.map(story => (
              <div className="story-container">
                <Link
                  className="story"
                  style={{
                    background: `url(${story.node.imageUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderColor: story.node.color,
                  }}
                  to={`/stories/${story.node.slug}`}
                ></Link>
                <span>{story.node.name}</span>
              </div>
            ))}
            <div className="story-container">
              <button
                className="story-show-collections"
                onClick={() => setShowStoryCollections(!showStoryCollections)}
              >
                {showStoryCollections ? <span>&#45;</span> : <span>&#43;</span>}
              </button>
              <span>Mais Stories</span>
            </div>
          </div>
        </div>

        <div className="stories-carousel">
          {showStoryCollections && (
            <div className="stories-carousel">
              <div className="stories">
                {collectionStories.map(story => (
                  <div className="story-container">
                    <Link
                      className="story"
                      style={{
                        background: `url(${story.node.imageUrl})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        borderColor: story.node.color,
                      }}
                      to={`/stories/${story.node.slug}`}
                    ></Link>
                    <span>{story.node.name}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div> */}
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
