import React, { useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import ICNCart from "../icons/iconCart"
import ICNSearch from "../icons/iconSearch"
import ICNFacebook from "../icons/iconFacebook"
import ICNInstagram from "../icons/iconInstagram"
import ICNVimeo from "../icons/iconVimeo"
import ICNYouTube from "../icons/iconYoutube"
import ICNSpotify from "../icons/iconSpotify"

import logo from "../images/iqara-logo.png"
import "./stickMenu.css"
import { Link } from "gatsby"
import useOutsideClick from "./utils/outsideClick"

import Search from "./search"

const StickMenu = ({ open, setOpen }) => {
  const ref = useRef()

  const data = useStaticQuery(graphql`
    query {
      allCategories {
        edges {
          node {
            name
            slug
          }
        }
      }

      allTags(limit: 5) {
        edges {
          node {
            name
            slug
          }
        }
      }
    }
  `)

  const allCategories = data.allCategories.edges.sort((a, b) =>
    a.node.name.localeCompare(b.node.name)
  )

  const allTags = data.allTags.edges.sort((a, b) =>
    a.node.name.localeCompare(b.node.name)
  )

  useOutsideClick(ref, () => {
    if (open) return setOpen(false)
  })

  return (
    <div
      className="mobile-stick-menu"
      style={{
        // transform: props.opened ? "translateY(13%)" : "",
        display: open ? "block" : "none",
      }}
      ref={ref}
    >
      <div className="menu-content">
        <div className="menu-logo">
          <img src={logo} alt="Iqara Islam" />
        </div>

        <div className="close-menu-btn" onClick={() => setOpen(!open)}>
          &times;
        </div>

        <div className="mobile-stick-menu-content">
          <div className="mobile-stick-menu-social">
            <a href="https://nizam.app/pt" target="_blank">
              <div
                height="26"
                style={{
                  paddingRight: 5,
                }}
                className="nizam-menu-logo"
              ></div>
            </a>

            {/* <a href="https://caravansaray.com.br" target="_blank">
              <ICNCart
                width="29"
                style={{
                  marginBottom: "-1px",
                  paddingRight: 15,
                  fill: "#906c42",
                }}
              />
            </a> */}

            <a
              href="https://www.facebook.com/IqaraIslam/"
              target="_blank"
              className="social-icons"
              rel="noreferrer"
            >
              <ICNFacebook width="30" style={{ marginBottom: "-2px" }} />
            </a>
            <a
              href="https://www.instagram.com/iqaraislam.port/"
              target="_blank"
              className="social-icons"
              rel="noreferrer"
            >
              <ICNInstagram
                width="29"
                style={{ marginBottom: "-1px", marginLeft: "-4px" }}
              />
            </a>
            <a
              href="https://vimeo.com/iqaraislam"
              target="_blank"
              className="social-icons"
              rel="noreferrer"
            >
              <ICNVimeo width="30" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCSBp8QB_C7Pocz1nroLUrrA"
              target="_blank"
              className="social-icons"
              rel="noreferrer"
              style={{ marginRight: "-5px" }}
            >
              <ICNYouTube
                width="50"
                fill="#906c42"
                style={{ marginBottom: "-12px", marginLeft: "-5px" }}
              />
            </a>
            <a
              href="https://open.spotify.com/show/51Ko9GfLJgeJTxzlIo8XoP"
              target="_blank"
              className="social-icons"
              rel="noreferrer"
            >
              <ICNSpotify
                width="27"
                style={{ marginBottom: "-1px", marginLeft: "4px" }}
              />
            </a>
          </div>

          <Search isOpen={true} />
        </div>

        <h3>Categorias</h3>
        <div className="menu-content-categories">
          <ul>
            {allCategories.slice(0, 8).map(({ node }) => (
              <li>
                <Link activeClassName="active" to={`/categorias/${node.slug}`}>
                  {node.name}
                </Link>
              </li>
            ))}
          </ul>

          <ul>
            {allCategories.slice(8, 16).map(({ node }) => (
              <li>
                <Link activeClassName="active" to={`/categorias/${node.slug}`}>
                  {node.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <hr />

        <h3>
          <Link to="/tags" style={{ color: "inherit", textDecoration: "none" }}>
            Tags
          </Link>
        </h3>

        <div className="menu-content-categories">
          <ul>
            {allTags.slice(0, 3).map(({ node }) => (
              <li>
                <Link activeClassName="active" to={`/tags/${node.slug}`}>
                  {node.name}
                </Link>
              </li>
            ))}
          </ul>

          <ul>
            {allTags.slice(3, 5).map(({ node }) => (
              <li>
                <Link activeClassName="active" to={`/tags/${node.slug}`}>
                  {node.name}
                </Link>
              </li>
            ))}
            <li>
              <Link to="/tags">
                <b>Ver todas {">"}</b>
              </Link>
            </li>
          </ul>
        </div>

        <hr />

        {/* <h3>Jornadas</h3>
        <div className="menu-content-categories">
          <ul>
            <li>
              <Link to="#">Professor</Link>
            </li>
            <li>
              <Link to="#">Aluno</Link>
            </li>
            <li>
              <Link to="#">Interessado</Link>
            </li>
          </ul>

          <ul>
            <li>
              <Link to="#">Religiosos</Link>
            </li>
            <li>
              <Link to="#">Historiadores</Link>
            </li>
            <li>
              <Link to="#">Autores</Link>
            </li>
          </ul>
        </div>

        <hr /> */}

        <h3>Iqara Islam</h3>
        <ul className="menu-content-static-pages">
          <li>
            <Link activeClassName="active" to="/quem-somos">
              Quem Somos
            </Link>
          </li>

          <li>
            <Link activeClassName="active" to="/politica-de-privacidade">
              Política de Privacidade
            </Link>
          </li>

          <li>
            <Link activeClassName="active" to="/direitos-dos-autores">
              Direitos dos Autores
            </Link>
          </li>

          <li>
            <Link activeClassName="active" to="/contato">
              Contato
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default StickMenu
