import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

import "./layout.css"
import "./embed.css"

import smallLogo from "../images/iqara-logo-small.png"
import burgerMenu from "../images/svg/icon-menu.svg"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuerySecond {
      site {
        siteMetadata {
          title
        }
      }

      allStories {
        edges {
          node {
            name
            slug
            images {
              image
              link
            }
            storyCollection
            imageUrl
            color
          }
        }
      }
    }
  `)

  const [open, setOpen] = useState(false)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        open={open}
        setOpen={setOpen}
        stories={data.allStories.edges}
      />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1100,
          padding: 0,
        }}
      >
        {children}
        <button
          className="mobile-menu-toggle-btn"
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <span>&times;</span>
          ) : (
            <img src={burgerMenu} alt="Iqara Islam" />
          )}
        </button>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
