import React from "react"

const ICNSpotify = ({ width, style }) => {
  return (
    <svg
      enable-background="new 0 0 30 30"
      height={width}
      id="Spotify"
      version="1.1"
      viewBox="0 0 30 30"
      width={width}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15,0C6.716,0,0,6.716,0,15s6.716,15,15,15c8.283,0,15-6.716,15-15S23.283,0,15,0z M21.086,22.078  c-0.244,0-0.408-0.084-0.635-0.223c-2.176-1.314-4.885-2.006-7.762-2.006c-1.605,0-3.22,0.206-4.731,0.52  c-0.246,0.054-0.555,0.149-0.739,0.149c-0.569,0-0.949-0.453-0.949-0.943c0-0.631,0.363-0.943,0.816-1.03  c1.853-0.422,3.698-0.663,5.614-0.663c3.283,0,6.207,0.753,8.725,2.262c0.375,0.219,0.594,0.441,0.594,0.996  C22.018,21.681,21.578,22.078,21.086,22.078z M22.719,18.107c-0.324,0-0.529-0.131-0.75-0.26c-2.441-1.447-5.822-2.409-9.525-2.409  c-1.899,0-3.539,0.266-4.896,0.627c-0.292,0.08-0.456,0.167-0.729,0.167c-0.646,0-1.172-0.526-1.172-1.177  c0-0.637,0.309-1.076,0.933-1.252c1.686-0.463,3.408-0.82,5.914-0.82c3.927,0,7.726,0.978,10.71,2.765  c0.502,0.288,0.686,0.651,0.686,1.186C23.889,17.583,23.371,18.107,22.719,18.107z M24.58,13.49c-0.305,0-0.486-0.074-0.771-0.23  c-2.715-1.628-6.93-2.524-11.004-2.524c-2.034,0-4.1,0.207-5.992,0.72c-0.218,0.055-0.493,0.164-0.769,0.164  c-0.8,0-1.414-0.633-1.414-1.433c0-0.815,0.505-1.273,1.05-1.434c2.138-0.63,4.525-0.924,7.119-0.924  c4.404,0,9.039,0.906,12.424,2.895c0.457,0.258,0.773,0.647,0.773,1.361C25.996,12.903,25.338,13.49,24.58,13.49z"
        id="WTF"
      />
    </svg>
  )
}

export default ICNSpotify
