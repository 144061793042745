import React from "react"

const ICNSearch = ({ width, style }) => {
  return (
    <svg
      fill="none"
      stroke="#fff"
      stroke-width="4"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      style={style}
    >
      <circle cx="12" cy="12" r="10" />
      <line x1="18" x2="30" y1="18" y2="30" />
    </svg>
  )
}

export default ICNSearch
