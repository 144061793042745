const CategorySchema = {
  "@context": "https://schema.org",
  "@graph": [
    {
      "@type": "Organization",
      "@id": "https://iqaraislam.com/#organization",
      name: "Iqara Islam",
      url: "https://iqaraislam.com/",
      sameAs: [
        "https://www.facebook.com/IqaraIslam",
        "https://www.instagram.com/iqaraislam.port/",
        "https://www.linkedin.com/company/iqara-islam",
        "https://www.youtube.com/channel/UCSBp8QB_C7Pocz1nroLUrrA",
      ],
      logo: {
        "@type": "ImageObject",
        "@id": "https://iqaraislam.com/#logo",
        inLanguage: "pt-BR",
        url:
          "https://iqaraislam.com/wp-content/uploads/2019/09/Logo-Iqara-Islam-Retina-1.png",
        width: 570,
        height: 180,
        caption: "Iqara Islam",
      },
      image: {
        "@id": "https://iqaraislam.com/#logo",
      },
    },
    {
      "@type": "WebSite",
      "@id": "https://iqaraislam.com/#website",
      url: "https://iqaraislam.com/",
      name: "Iqara Islam",
      inLanguage: "pt-BR",
      description: "Sua Fonte de Islam Sunita",
      publisher: {
        "@id": "https://iqaraislam.com/#organization",
      },
      potentialAction: {
        "@type": "SearchAction",
        target: "https://iqaraislam.com/?s={search_term_string}",
        "query-input": "required name=search_term_string",
      },
    },
    {
      "@type": "CollectionPage",
      "@id": "https://iqaraislam.com/categorias/islam-brasileiro/#webpage",
      url: "https://iqaraislam.com/categorias/islam-brasileiro/",
      name: "Tudo sobre o Islam Brasileiro | Iqara Islam",
      isPartOf: {
        "@id": "https://iqaraislam.com/#website",
      },
      inLanguage: "pt-BR",
      description:
        "Reunimos em um só lugar artigos que tratam sobre a comunidade islâmica brasileira, apresentando a perspectiva de muçulmanos do país sobre diversos assuntos.",
    },
  ],
}

export default CategorySchema
