import React from "react"
import { Link } from "gatsby"

import logo from "../images/iqara-logo-white.png"

import ICNMadeWith from "../icons/iconMadeWith"

const Footer = () => {
  const currentDate = new Date()

  return (
    <footer>
      <div className="footer-container">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Iqara Islam" />
          </Link>
        </div>

        <nav>
          <ul>
            <li>
              <Link to="/tags">Tags</Link>
            </li>
            <li>
              <Link to="/quem-somos">Quem Somos</Link>
            </li>
            <li>
              <Link to="/politica-de-privacidade">Política de Privacidade</Link>
            </li>
            <li>
              <Link to="/direitos-dos-autores">Direitos dos Autores</Link>
            </li>
            <li>
              <Link to="/contato">Contato</Link>
            </li>
          </ul>
        </nav>
      </div>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1100,
          padding: 0,
        }}
      >
        <p className="footer-made-with">
          © 2014 - {currentDate.getFullYear()} Iqara Islam | Feito por amor a{" "}
          <ICNMadeWith width={27} /> pela Taha Brasil
        </p>
      </div>
    </footer>
  )
}

export default Footer
